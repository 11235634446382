import { createWebHistory, createRouter } from "vue-router";
import SignIn from "@/components/auth/SignIn.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  if (localStorage.getItem('user')) isAuthenticated = true
  else isAuthenticated = false
  if (isAuthenticated) next()
  else next('/login')
}


const routes = [
  { path: "/", component: SignIn, meta: { title: "Sign In" } },
  { path: "/login", component: SignIn, meta: { title: "Sign In" } },
  { path: "/toll-transponders", component: () => import("@/components/dashboard/dashboard.vue"), beforeEnter: authGuard, meta: { title: "Toll Transponders", back: "client-logs" },},
  { path: "/client-logs", component: () => import("@/components/dashboard/ClientLog.vue"), beforeEnter: authGuard, meta: { title: "Client Logs" , back: "toll-transponders"}, },
  { path: "/index.html", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;